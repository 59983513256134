export default {
  app: {
    state: {
      test: {},
      lang: localStorage.getItem('locale') || 'ru',
      translates: {},
      load: false,
      _save: [ 'lang', 'translates', ],
      lastActivity: '',
      isAccessBlock: false,
      timeRemaining: 0,
      companiesBlock: {},
      company: {},
    },
    getters: {
      translates(state) {
        return state.translates
      },
      lang(state) {
        return state.lang
      },
      lastActivity(state) {
        return state.lastActivity
      },
      load(state) {
        return state.load
      },
      isAccessBlock(state) {
        return state.isAccessBlock
      },
      timeRemaining(state) {
        return state.timeRemaining
      },
      companiesBlock(state) {
        return state.companiesBlock
      }, 
      company(state) {
        return state.company
      }, 

    },
    mutations: {
      lang(state, value) {
        state.lang = value
      },
      translates(state, value) {
        state.translates = value
      },
      lastActivity(state, value) {
        state.lastActivity = value
      },
      load(state, value) {
        state.load = value
      },
      isAccessBlock(state, value) {
        state.isAccessBlock = value
      },
      timeRemaining(state, value) {
        state.timeRemaining = value
      },
      companiesBlock(state, value) {
        state.companiesBlock = value;
      },  
      isBlocked(state, value) {
        const blocked = state.companiesBlock.blockedCompanies.find((item) => item.companyId === value)
        state.isAccessBlock = blocked.isActive
        state.timeRemaining = (new Date(blocked.endAt).getTime() - new Date().getTime()) / 1000
      },
      setCompany(state, value) {
        state.company = value;
      },  
    },
    actions: {
      updateUser() {
        return $api.auth.info()
          .with('company')
          .with('salaryStats')
          .with('monthTaken')
          .then((response) => {
            $app.auth.user(response.data.content.user)
          })
          .catch((e) => {
            console.log(e);
            $app.ui.notify('Упс... Что-то пошло не так', 'danger')
          })
      },
      setLastActivity({ state, commit, }, value) {
        commit('lastActivity', value)
      },
      initLang({ state, dispatch, }) {
        localStorage.getItem('locale') ? dispatch('setLang', localStorage.getItem('locale')) : dispatch('setLang', state.lang)
      },
      setLang({ state, commit, }, value) {
        $app.api.config({
          headers: {
            'Locale': value,
          },
        })
        localStorage.setItem('locale', value)
        commit('lang', value)
        commit('load', true)
        $api.lang.items().then((response) => {
          commit('translates', response.data.content )
          $app.api.config({
            headers: {
              'Locale': localStorage.getItem('locale'),
            },
          })
        }).finally(() => {
          commit('load', false)
        })
      },
      getAccessBlock({ commit, state }, { userId, selectedCompany }) {
        commit('load', true)
        
        $api.accessBlock.get( userId, selectedCompany ).then((response) => {
          console.log('No active access blocks!');

          if (response.status === 200) {
            commit('isAccessBlock', false)
          }

        }).catch((error) => {
          if (error.response.status === 423) {
            commit('companiesBlock', error.response.data )
            commit('isBlocked', selectedCompany )
          }
        }).finally(() => {
          commit('load', false)
        })
      },
    },
    namespaced: true,
  },
}
