<!-- eslint-disable max-len -->
<template>
  <div class="payment-info">
    <div class="payment-success">
      <div class="success-icon">
        <svg width="320" height="200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M154.987 36.8076C172.84 56.4912 194.393 50.0911 211.349 45.3906C240.245 37.3798 265.708 101.752 222.507 124.926C173.374 151.282 148.693 202.459 113.216 160.975C77.7401 119.49 130.669 109.191 102.059 72.2839C73.4486 35.3771 132.671 12.203 154.987 36.8076Z"
            fill="#912EDE" />
          <path
            d="M105.544 85.131C131.234 78.337 135.469 56.2556 139.097 39.0388C145.28 9.6972 214.149 16.7081 214.848 65.7273C215.642 121.477 249.724 166.937 196.568 179.348C143.413 191.759 158.621 140.027 112.689 148.447C66.757 156.868 73.4306 93.6235 105.544 85.131Z"
            fill="#DCB6FA" fill-opacity="0.44" />
          <rect x="129" y="65.6246" width="72" height="72" rx="36" fill="white" />
          <rect x="145" y="81.6246" width="40" height="40" rx="20" fill="#912EDE" />
          <path d="M155 103L157 101L162 106L173 95L175 97L162 110L155 103Z" fill="white" />
        </svg>
      </div>
      <div class="payment-title">
        <h2 data-lang="title">
          <t name="5.6.7" />
        </h2>
        <p class="gray-text">
          <t name="5.7.4" />
        </p>
      </div>
      <div class="payment-content">
        <div class="head-row">
          <div class="data-key" data-lang="order_number">
            <t name="5.7.5" />&nbsp;
          </div>
          <div class="data-value">
            <span>№{{ operationId }}</span>
          </div>
        </div>

        <div class="line" />

        <div class="data-row">
          <div class="data-key" data-lang="amount">
            <t name="5.6.9" />:
          </div>
          <div class="data-value">
            <span>{{ amount }} ₸</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-key" data-lang="amount">Комиссия:</div>
          <div class="data-value">
            <span>{{ commission }} ₸</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-key" data-lang="amount">
            <t name="5.7.6" />:
          </div>
          <div class="data-value">
            <span>{{ +amount + +commission }} ₸</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-key" data-lang="date">
            <t name="5.7.0" />:
          </div>
          <div class="data-value">
            <span>{{ dateTime }}</span>
          </div>
        </div>
      </div>
      <button class="close-payment-button" @click="redirect">
        <t name="1.11.12" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CModalSuccess',
  props: {
    operationId: {
      required: true
    },
    amount: {
      required: true
    },
    commission: {
      required: true
    },
    dateTime: {
      required: true
    }
  },
  methods: {
    redirect() {
      this.$router.push({ name: 'mobile.index', })
    },
  }
}

</script>




<style scoped lang="scss" src="./modal-success.scss"></style>
